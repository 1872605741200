import "@fontsource/montserrat";
import React from "react";
import Logo from "../assets/images/logo.png";
import LayoutComponent from "../components/LayoutComponent";
import Seo from "../components/seo";
import "../styles/pages.scss";

const IndexPage: React.FC<{}> = () => {
  return (
    <LayoutComponent hideGreenBox>
      <Seo title="AGB" />
      <div className="content-box agb-wrapper">
        <img src={Logo} alt="Corack Logo" />
        <h2>Anbieter:</h2>
        <p>Duckeneers GmbH | Leitzstrasse 45 | 70469 Stuttgart</p>
        <p>Telefon: +49 (0) 711 490 66 138</p>
        <p>Web: https://www.duckeneers.de</p>
        <h2>Leistungsbeschreibung:</h2>
        Im Zuge der Covid-19-Pandemie ist es seit dem 24.11.2021 für Arbeitgeber
        verpflichtend den 3G Status der Mitarbeiter am Arbeitsplatz zu erfassen.
        Mitarbeiter im Homeoffice sind davon nicht betroffen. Der erhobene
        Status ist nach spätestens 6 Monaten zu löschen.
        <br />
        Mit unserer App können Sie den 3G Status Ihrer Mitarbeiter einfach
        erfassen. Wir gewährleisten eine datenschutzrechtlich konforme
        Speicherung und rechtzeitige anschließende Löschung gemäß den
        Covid-19-Verordnungen aller Bundesländer. Ihre Mitarbeiter können
        selbstständig, sofern Sie die entsprechende Rolle innerhalb der App
        haben, den 3G Status der Kollegen protokollieren. Sie können die
        erhobenen Daten entweder via API-Key in Ihre eigene Auswertung einbinden
        oder über eine Übersicht in unserer App einsehen. <br />
        Eine Kontrolle unsererseits bezüglich Vollständigkeit bzw. Plausibilität
        der vom Nutzer eingegebenen Daten erfolgt nicht.
        <h2>Anmeldung von Ihnen als Auftraggeber:</h2>
        Zur Anmeldung kontaktieren Sie unser Team über das Formular auf unserer
        Homepage. Sie erhalten dann innerhalb von 24 Stunden eine Antwort mit
        Zugangsdaten zu Ihrem Account. Ihr Nutzer-Account ist passwortgeschützt
        für Sie erreichbar. <br />
        Der Abschluss des App-Nutzungsvertrags kommt allerdings erst zustande,
        wenn wir Ihnen mit einer weiteren E-Mail den Vertragsschluss bestätigen
        und Ihnen für Ihre Unterlagen eine datenschutzrechtliche
        Auftragsdatenverarbeitungsvereinbarung (Art.28 DSGVO) zusenden. Die für
        den Vertragsschluss zur Verfügung stehende Sprache ist Deutsch. Wir
        speichern den Vertragstext und senden Ihnen die Bestelldaten im Rahmen
        der Eingangsbestätigungs-Email zu.
        <h2>Zahlung</h2>
        Pro erfasstem Mitarbeiter berechnen wir dem nutzenden Auftraggeber eine
        Vergütung von € 1,50 zzgl. MwSt. pro Monat. Dieser Betrag enthält alle
        sonstigen Preisbestandteile für unseren Service. Die Abrechnung erfolgt
        monatlich. Wir senden Ihnen zum Anfang des jeweils nächsten Monats eine
        elektronische Abrechnung per E-Mail zu. Sofern Sie nicht innerhalb von
        drei Wochen einer Abrechnung widersprechen, gelten die dort abgerechnete
        Anzahl von erfassten Mitarbeitern als akzeptiert, so dass Sie danach im
        Streitfall deren Unrichtigkeit nachweisen müssen.
        <h2>Datenschutz</h2>
        Wir erfassen und speichern den 3G Status in Ihrem Auftrag. Gemäß Art.28
        DSGVO ist dazu der Abschluss einer datenschutzrechtlichen
        Auftragsverarbeitungsvereinbarung erforderlich, dessen Vertragsmuster
        Sie hier einsehen können. Mit Ihrer Bestellung unseres Service stimmen
        Sie dem Abschluss dieser datenschutzrechtlichen
        Auftragsverarbeitungsvereinbarung zu und wir senden Ihnen mit unserer
        Vertragsbestätigung eine maschinell erstellte Ausfertigung der
        datenschutzrechtlichen Auftragsverarbeitungsvereinbarung per E-Mail zu.
        Eine handschriftliche Unterzeichnung ist nicht erforderlich. Gegenüber
        den Nutzern gilt unsere Datenschutzerklärung.
        <h2>Vertragslaufzeit und Kündigung</h2>
        Sie können unseren Service jederzeit ohne Mindestlaufzeit zum Monatsende
        kündigen, wobei der Service dann bis zum Ende des laufenden Monats
        abgestellt und danach eine abschließende Abrechnung erfolgt. Das
        gesetzliche Recht zur außerordentlichen Kündigung aus wichtigem Grund
        bleibt unberührt.
        <h2>Selbstbelieferungsvorbehalt</h2>
        Wenn wir die vertraglich vereinbarte Leistung ohne eigenes Verschulden
        nicht erbringen können, weil bspw. ein Lieferant oder Dienstleister uns
        gegenüber seine Leistung nicht erbringt, werden wir Sie unverzüglich
        informieren und Ihnen eine vergleichbare Ersatzleistung vorschlagen.
        Wenn keine vergleichbare Leistung möglich ist oder Sie keine andere
        Leistung wünschen, dürfen Sie wie auch wir vom Vertrag zurücktreten bzw.
        kündigen, wobei bis dahin erbrachte Leistungen abschließend abgerechnet
        werden und zu vergüten sind.
        <h2>Haftungen</h2>
        Wir haften unbeschränkt (i) bei Vorsatz oder grober Fahrlässigkeit, (ii)
        für arglistig verschwiegene Mängel, (iii) für die Verletzung von Leben,
        Leib oder Gesundheit, (iv) nach den Vorschriften des
        Produkthaftungsgesetzes und/oder (v) für Mängel, für deren Ausbleiben
        wir eine Garantie übernommen haben. Bei der Verletzung einer
        wesentlichen Vertragspflicht, also einer Pflicht, deren Erfüllung für
        das Erreichen des Vertragszwecks erforderlich ist und auf deren
        Einhalten unser Vertragspartner regelmäßig vertraut bzw. vertrauen darf,
        haften wir im Falle leichter Fahrlässigkeit, soweit der Schaden nach der
        Art des fraglichen Geschäfts vorhersehbar und vertragstypisch ist. Eine
        weitergehende Haftung besteht nicht. Die vorstehende
        Haftungsbeschränkung gilt auch für die persönliche Haftung unserer
        Mitarbeiter, Erfüllungsgehilfen und Vertreter. Der Einwand des
        Mitverschuldens unseres Vertragspartners bleibt uns davon unbenommen.
        <h2>Schlussbestimmungen</h2>
        Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des
        UN-Kaufrechts und des Kollisionsrechts. Sofern der Auftraggeber
        Kaufmann, eine juristische Person des öffentlichen Rechts oder ein
        öffentlich-rechtliches Sondervermögen ist oder wenn der Auftraggeber
        seinen Hauptsitz im Ausland hat, ist Stuttgart ausschließlicher
        Gerichtsstand für alle Streitigkeiten aus und im Zusammenhang mit den
        vertragsgegenständlichen Leistungen. Wir können diese
        Vertragsbedingungen zukünftig ändern. Die neue Fassung gilt, wenn wir
        sie Ihnen zusenden, wobei die Überlassung durch Zusenden oder durch
        Mitteilung eines Internetpfads für den Online-Abruf erfolgen kann, und
        Sie nicht innerhalb von 4 Wochen danach der Geltung der neuen AGB
        widersprechen. Dies gilt zudem nur, sofern wir Sie auf die
        Widerspruchsfrist und die Rechtsfolge eines unterbleibenden Widerspruchs
        bei der Überlassung der neuen AGB hinweisen.
      </div>
    </LayoutComponent>
  );
};

export default IndexPage;
